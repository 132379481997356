import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = ['modal', 'cancel-form'];
  modalInstance = null;

  connect() {
    this.open();
    this.closeOnTurboSubmit();
  }

  open() {
    this.modalInstance = new Modal(this.modalTarget, { backdrop: true });
    this.modalInstance.show();
  }

  hideBackdrop() {
    if (this.modalInstance) {
      this.modalInstance._config.backdrop = false;
      this.modalInstance._adjustBackdrop();
    }
  }

  showBackdrop() {
    if (this.modalInstance) {
      this.modalInstance._config.backdrop = true;
      this.modalInstance._adjustBackdrop();
    }
  }

  closeOnTurboSubmit() {
    document.addEventListener('turbo:submit-start', () => {
      this.modalInstance.hide();
    });
  }
}
