$(document).on('turbolinks:load', () => {
  $('.js-select').select2({
    width: '100%',
    placeholder: 'Select',
    allowClear: true
  });
});

$(document).on('turbolinks:before-cache', function(){
  $('.js-select').select2('destroy');
});
