$(document).on('turbolinks:load', () => {

  $('#js-reference-input').on('change keydown paste input', function() {
    const value = $('#js-reference-input').val();
    const strippedValue = value.replace(/\s+/g, '');
    $('#js-reference-input').val(strippedValue);
    if (/^[^\W_]{6}$/.test(strippedValue)){
      $('#js-reference-input').removeClass('is-invalid');
      $('#js-reference-input').addClass('is-valid');
      $('#js-error-message').addClass('d-none');
    } else {
      $('#js-reference-input').addClass('is-invalid');
      $('#js-error-message').removeClass('d-none');
    }
  });

  $('.js-search-form').on('ajax:complete', function(event) {
    const a = $('<a>', {
      href: event.detail[0].responseURL,
    });
    history.pushState({ turbolinks: {} }, '', a.prop('search'));
  });

  $('.js-datepicker').flatpickr({ enableTime: true, dateFormat: "d.m.Y H:i" });
});
