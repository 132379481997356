function populateDeviceDetails(element, data) {
  if ($.isEmptyObject(data)) {
    return;
  }

  const $formObject = element.closest('.js-device-inputs');

  const $deviceCodeField = $formObject.find('.js-device-code');
  const $deviceTypeField = $formObject.find('.js-device-type-select');

  $deviceCodeField.val(data['deviceCode']);
  $deviceTypeField.val(data["deviceTypeId"]).trigger('change');
}

function clearDeviceDetails(element) {
  const $formObject = element.closest('.js-device-inputs');

  const $deviceCodeField = $formObject.find('.js-device-code');
  const $deviceTypeField = $formObject.find('.js-device-type-select');

  $deviceCodeField.val("");
  $deviceTypeField.val("").trigger('change');
}

$(document).on('turbolinks:load', function(){
  const $deviceSelect = $('.js-device-select');

  $deviceSelect.each(function(){
    populateDeviceDetails($(this), $(this).data());
  });

  const ajaxParams = {
    url: '/search/devices',
    dataType: 'json',
    type: 'GET',
    delay: 300,
    data: function(params) {
      return {
        query: params.term
      };
    },
    processResults: function(data) {
      return {
        results: $.map(data, function(device) {
          return {
            id: device.id,
            text: device.device_details,
            deviceCode: device.code,
            deviceTypeId: device.device_type_id
          };
        })
      };
    },
  };

  $deviceSelect.select2({
    width: '100%',
    allowClear: true,
    placeholder: 'Select',
    minimumInputLength: 6,
    ajax: ajaxParams,
  }).on('select2:select', function(e) {
    populateDeviceDetails($(this), e.params.data);
  }).on('select2:clear', function() {
    clearDeviceDetails($(this));
  });
});
