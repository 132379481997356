$(document).on('turbolinks:load', () => {
  const $deviceVersionSelect = $('.js-cases-device-version-select');
  $deviceVersionSelect.on('select2:select', deviceVersionSelected);
  $deviceVersionSelect.on('select2:clear', deviceVersionCleared);

  initializeFormFields();

  $('#js-return-confirmation-btn').on('click', function(event) {
    event.preventDefault();
    $('#js-return-confirmation-modal').show();
  });

  $("#js-return-confirmation-modal .btn-no").on('click', function() {
    $("#js-return-confirmation-modal").hide();
  });

  $("#js-return-confirmation-modal .btn-yes").on('click', function() {
    $('#js-return-confirmation-btn').closest('form').submit();
    $("#js-return-confirmation-modal").hide();
  });
});


function initializeFormFields() {
  const $deviceVersionSelect = $('.js-cases-device-version-select');
  if (isNotPresent($deviceVersionSelect)){
    return;
  }

  const selection = $deviceVersionSelect.select2('data')[0];
  const isDeviceSelected = selection.id;

  if (isDeviceSelected) {
    const selectEventData = {
      type: 'select2:select',
      params: {
        data: selection
      }
    };

    $deviceVersionSelect.trigger(selectEventData);
  } else {
    $deviceVersionSelect.trigger('select2:clear');
  }
}

function isNotPresent(element) {
  return element.length === 0;
}

function deviceVersionSelected() {
  $('#single-item-device-fields').show();

  const selection = $('select#trade_in_case_device_version_id :selected').data();
  if (selection.isSingleItemDevice) {
    $('#bundled-device-fields').hide();
  } else {
    $('#bundled-device-fields').show();
  }
}

function deviceVersionCleared() {
  $('#single-item-device-fields').hide();
  $('#bundled-device-fields').hide();
}
