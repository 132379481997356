$(document).on('turbolinks:load', () => {
  $('#js-registration-save-btn').click(function(event) {
    const expertInfoValue = $('#js-expert-info-input').val();
    const serialNumberValue = $('#js-serial-number-input').val();
    if (expertInfoValue.match(/^\d{9}$/)) {
      event.preventDefault();
      $.get('/search/registered_telephone_numbers/' + expertInfoValue, function(data) {
        if (data.registered_telephone_number) {
          $('#new_registration').submit();
        } else {
          $('#js-non-existing-phone-modal').show();
        }
      });
    } else if (expertInfoValue === '' || serialNumberValue === '') {
      event.preventDefault();
      $('#js-blank-field-confirmation-modal').show();
    }
  });

  $('#js-blank-field-modal-close-btn').click(function() {
    $('#js-blank-field-confirmation-modal').hide();
  });

  $('#js-blank-field-modal-submit-btn').click(function() {
    $('#js-blank-field-confirmation-modal').hide();
    $('#new_registration').submit();
  });

  $('#js-non-existing-iqos-card-modal-close-btn').click(function() {
    $('#js-non-existing-iqos-card-modal').hide();
  });

  if ($('#js-non-existing-iqos-card-modal').data('show-modal')) {
    $('#js-non-existing-iqos-card-modal').show();
  }

  $('#js-non-existing-phone-modal-close-btn').click(function() {
    $('#js-non-existing-phone-modal').hide();
  });

  $('#js-non-existing-phone-modal-submit-btn').click(function() {
    $('#js-non-existing-phone-modal').hide();
    $('#new_registration').submit();
  });

  $('.js-disabled-enter').keypress(function(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  });
});
